/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *main_reflector.js*
 *
 * ### Content
 *   * everything that should be exported from the reflector build
 *
 */

(function() {
  // get the constructor of the reflector
  var REFLECTOR = require('../src/api/v2/ApiReflectorV2.1');
  var GLOBAL_UTILS = require('../src/shared/util/GlobalUtils');
  // array of iframe ids we should instantiate reflectors for
  var iframe_ids = ['sdv-iframe'];
  if ( Array.isArray(window.SDVIframeIds) ) {
    iframe_ids = window.SDVIframeIds.slice();
  } else {
    window.SDVIframeIds = iframe_ids.slice();
  }
  // try to get iframe for event
  var try_get_iframe_for_event = function(event) {
    var iframeId, iframe, i, imax = iframe_ids.length;
    for (i=0; i<imax; i++) {
      iframeId = iframe_ids[i];
      iframe = document.getElementById( iframeId );
      if (iframe && iframe.src && iframe.src.indexOf(event.origin) === 0 && event.data && event.data.iframeId === iframeId) {
        iframe_ids.splice(i, 1);
        return iframe;
      }
    }
  };
  // we initialize the reflector upon receiving the first message from the iframe
  var init_reflector = function(event) {
    // we ignore all messages except the ones from the ShapeDiver iframe
    var iframe = try_get_iframe_for_event(event);
    // if there are no more iframes we de-register ourselves
    if ( iframe_ids.length === 0 ) {
      window.removeEventListener('message', init_reflector, false);
    }
    // if we got a matching iframe, instantiate a reflector, it will set up it's own event listener
    if ( iframe ) {
      // should we instantiate the reflector in debug mode?
      var debug = GLOBAL_UTILS.getNodeAttribute(iframe, 'debug', false);
      // instantiate reflector
      var reflector = new REFLECTOR(iframe.contentWindow, event.origin, !!debug, iframe.id);
      if (!window.SDVReflector) {
        window.SDVReflector = reflector;
      }
      if (!window.SDVReflectors) {
        window.SDVReflectors = {};
      }
      window.SDVReflectors[iframe.id] = reflector;
      // process the message in the reflector
      reflector.receiveMessage(event);
    }
  };
  window.addEventListener('message', init_reflector, false);
})();
